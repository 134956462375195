import FooterLinks from "./FooterLinks";
import DownloadApps from "./DownloadApps";
import FooterSocials from "./FooterSocials";
import FooterContactUs from "./FooterContactUs";
import CallBell from "@/components/icons/CallBell";
import EmailSubscribe from "@/components/forms/subscribe";
import { useGlobalData } from "@/context/global";
import { StaticLinks } from "@/constants/Layout";
import CompareProductFooter from "./CompareProductFooter";

const Footer = ({ t }) => {
  const {
    appData: { megaMenuTreeData },
  } = useGlobalData();

  return (
    <footer className="pt-5 pb-1 text-white bg-black sm:pt-10 sm:pb-10">
      <div className="grid grid-cols-9 gap-2 sm:gap-6 lg:grid-cols-4 w-11/12 mx-auto">
        <div className="max-lg:col-span-full">
          <div className="flex flex-col gap-5 max-sm:flex-col-reverse max-sm:gap-2 max-sm:mb-5">
            <FooterSocials t={t} />
            <DownloadApps t={t} />
          </div>
          <EmailSubscribe t={t} />
          <div className="items-center hidden gap-5 sm:flex sm:my-5">
            <CallBell className="text-red-600 max-sm:size-7" />
            <div className="flex flex-col text-sm">
              <span className="mb-1">{t("Need Help ?")}</span>
              <span className="font-bold">920000440</span>
            </div>
          </div>
        </div>
        <FooterLinks
          title={t("Useful Links")}
          data={megaMenuTreeData}
          has_imgs
        />
        <FooterLinks title={t("Useful Links")} data={StaticLinks} is_static />
        <FooterContactUs t={t} />
      </div>
      <CompareProductFooter />
    </footer>
  );
};

export default Footer;
